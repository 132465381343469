import { sleep } from "../../core/AEngine.js";
export class ARoutePlannerService {
    constructor() {
        this.m_enabled = false;
    }
    compareRouteRequestSettings(A, B) {
        if (A.Areas.length != B.Areas.length || !A.Areas.every(item => B.Areas.find(item2 => item == item2)))
            return false;
        if (A.Zones.length != B.Zones.length || !A.Zones.every(item => B.Zones.find(item2 => item == item2)))
            return false;
        if (A.RouteAreas.length != B.RouteAreas.length || !A.RouteAreas.every(item => B.RouteAreas.find(item2 => item == item2)))
            return false;
        if (A.AllStreets != B.AllStreets)
            return false;
        if (A.WithoutStatistics != B.WithoutStatistics)
            return false;
        if (A.MinOccupancy != B.MinOccupancy)
            return false;
        if (A.MaxOccupancy != B.MaxOccupancy)
            return false;
        if (A.MinCompliancy != B.MinCompliancy)
            return false;
        if (A.MaxCompliancy != B.MaxCompliancy)
            return false;
        if (A.MinVisitorRate != B.MinVisitorRate)
            return false;
        if (A.MaxVisitorRate != B.MaxVisitorRate)
            return false;
        if (A.MinCompliancyVisitors != B.MinCompliancyVisitors)
            return false;
        if (A.MaxCompliancyVisitors != B.MaxCompliancyVisitors)
            return false;
        if (A.MinEnforcementIntensity != B.MinEnforcementIntensity)
            return false;
        if (A.MaxEnforcementIntensity != B.MaxEnforcementIntensity)
            return false;
        return true;
    }
    async fetchGeoMap() {
        if (this.m_geoMap) {
            console.log("geomap already exists");
            return this.m_geoMap;
        }
        else {
            console.log("fetching geomap...");
            const geoMapPromise = new Promise((resolve) => {
                CCCClient.SendMessage("PlanRoute_GetGeoMap_Request", 1, null, 0, {
                    Type: "ControlCenter",
                    IndexNumber: 1,
                    CustomerNumber: CCCClient.NodeCustomerNumber,
                    ProjectNumber: CCCClient.NodeProjectNumber //CCCClient.NodeProjectNumber
                });
                Events.once(`PlanRoute_GetGeoMap_Response`, response => {
                    this.m_geoMap = response.GeoMap;
                    if (!this.m_geoMap.Areas)
                        this.m_geoMap.Areas = {};
                    if (!this.m_geoMap.Zones)
                        this.m_geoMap.Zones = {};
                    if (!this.m_geoMap.RouteAreas)
                        this.m_geoMap.RouteAreas = {};
                    if (!this.m_geoMap.WaySegments)
                        this.m_geoMap.WaySegments = {};
                    console.log(this.m_geoMap);
                    resolve(this.m_geoMap);
                });
            });
            return await geoMapPromise;
        }
    }
    async pollRouteService() {
        if (this.m_enabled) {
            return this.m_enabled;
        }
        else {
            const geoStatusPromise = new Promise((resolve, reject) => {
                CCCClient.SendMessage("PlanRoute_GetStatus_Request", 1, null, 0, {
                    Type: "ControlCenter",
                    IndexNumber: 1,
                    CustomerNumber: CCCClient.NodeCustomerNumber,
                    ProjectNumber: CCCClient.NodeProjectNumber //CCCClient.NodeProjectNumber
                });
                Events.once(`PlanRoute_GetStatus_Response`, response => {
                    if (response.message == 'running')
                        this.m_enabled = true;
                    else
                        this.m_enabled = false;
                    resolve(this.m_enabled);
                });
                sleep(1000).then(async (_) => {
                    resolve(false);
                });
            });
            return await geoStatusPromise;
        }
    }
    async fetchRequests() {
        const ares = await requestService.fetch({
            AssertValues: true,
            Query: (`
        SELECT
          Id as RouteId,
          Status,
          StatusText,
          RouteName, 
          ParkingStreetIds,
          Settings,
          PreferRight,
          NumRoutes,
          planroute_requests.MaxValue,
          MaxIteration,
          InitialCoolingRate,
          Created,
          Finished
        FROM planroute_requests
        ORDER BY Status DESC, Created
      `)
        }, {
            valueMapper: {
                Created: (v) => new Date(v)
            }
        });
        let result = ares.toArray();
        for (const r of result) {
            let $s = r.Settings;
            if ($s.Occupancy || $s.Compliancy || $s.VisitorRate || $s.CompliancyVisitors || $s.EnforcementIntensity) {
                let MinOccupancy, MaxOccupancy, MinCompliancy, MaxCompliancy, MinCompliancyVisitors, MaxCompliancyVisitors, MinVisitorRate, MaxVisitorRate, MinEnforcementIntensity, MaxEnforcementIntensity;
                if ($s.Occupancy.includes('/')) {
                    const array = $s.Occupancy.split("/");
                    if (array.length === 2) {
                        MinOccupancy = parseFloat(array[0]);
                        MaxOccupancy = parseFloat(array[1]);
                    }
                }
                if ($s.Compliancy.includes('/')) {
                    const array = $s.Compliancy.split("/");
                    if (array.length === 2) {
                        MinCompliancy = parseFloat(array[0]);
                        MaxCompliancy = parseFloat(array[1]);
                    }
                }
                if ($s.CompliancyVisitors.includes('/')) {
                    const array = $s.CompliancyVisitors.split("/");
                    if (array.length === 2) {
                        MinCompliancyVisitors = parseFloat(array[0]);
                        MaxCompliancyVisitors = parseFloat(array[1]);
                    }
                }
                if ($s.VisitorRate.includes('/')) {
                    const array = $s.VisitorRate.split("/");
                    if (array.length === 2) {
                        MinVisitorRate = parseFloat(array[0]);
                        MaxVisitorRate = parseFloat(array[1]);
                    }
                }
                if ($s.EnforcementIntensity.includes('/')) {
                    const array = $s.EnforcementIntensity.split("/");
                    if (array.length === 2) {
                        MinEnforcementIntensity = parseFloat(array[0]);
                        MaxEnforcementIntensity = parseFloat(array[1]);
                    }
                }
                r.Settings = {
                    Areas: $s.Areas,
                    Zones: $s.Zones,
                    RouteAreas: $s.RouteAreas,
                    AllStreets: $s.AllStreets,
                    WithoutStatistics: $s.WithoutStatistics,
                    MinOccupancy: MinOccupancy,
                    MaxOccupancy: MaxOccupancy,
                    MinCompliancy: MinCompliancy,
                    MaxCompliancy: MaxCompliancy,
                    MinCompliancyVisitors: MinCompliancyVisitors,
                    MaxCompliancyVisitors: MaxCompliancyVisitors,
                    MinVisitorRate: MinVisitorRate,
                    MaxVisitorRate: MaxVisitorRate,
                    MinEnforcementIntensity: MinEnforcementIntensity,
                    MaxEnforcementIntensity: MaxEnforcementIntensity
                };
            }
        }
        return result;
    }
    async fetchRoutes() {
        let ares = await requestService.fetch({
            AssertValues: true,
            Query: (`
        SELECT 
          r.RouteId, 
          r.RouteName, 
          r.CarNumber, 
          r.Cost, 
          r.Length,
          r.Requestcreated_datetime, 
          ST_AsGeoJSON(r.RouteGpsLine) AS RouteGpsLine,
          r.Active,
          r.RouteSegments,
          r.GeoDataTimeStamp
        FROM planroute_routes r 
        WHERE r.Finished = 1
        ORDER BY Active DESC, RouteName ASC
      `),
        }, {
            valueMapper: {
                CarNumber: (v) => parseInt(v),
                Cost: (v) => parseInt(v),
                Length: (v) => parseInt(v),
                Requestcreated_datetime: (v) => new Date(v),
                GeoDataTimeStamp: (v) => new Date(v),
            }
        });
        return ares.toArray();
    }
    async fetchRoute(id, carnumber) {
        let ares = await requestService.fetch({
            AssertValues: true,
            Query: (`
        SELECT 
          r.RouteId, 
          r.RouteName, 
          r.CarNumber, 
          r.Cost, 
          r.Length,
          r.Requestcreated_datetime, 
          ST_AsGeoJSON(r.RouteGpsLine) AS RouteGpsLine,
          r.Active,
          r.RouteSegments,
          r.GeoDataTimeStamp
        FROM planroute_routes r 
        WHERE r.Finished = 1 AND r.RouteId = :RouteId
        ORDER BY Active DESC, RouteName ASC
      `),
            Params: { RouteId: id }
        }, {
            valueMapper: {
                CarNumber: (v) => parseInt(v),
                Cost: (v) => parseInt(v),
                Length: (v) => parseInt(v),
                Requestcreated_datetime: (v) => new Date(v),
                GeoDataTimeStamp: (v) => new Date(v),
            }
        });
        return ares.toArray();
    }
    async fetchAndCacheRegime(from, to) {
        const data = {
            "From": from,
            "To": to,
        };
        const regimePromise = new Promise((resolve) => {
            CCCClient.SendMessage("PlanRoute_ValidateRegimeTime_Request", 1, data, 0, {
                Type: "ControlCenter",
                IndexNumber: 1,
                CustomerNumber: CCCClient.NodeCustomerNumber,
                ProjectNumber: CCCClient.NodeProjectNumber //CCCClient.NodeProjectNumber
            });
            Events.once(`PlanRoute_ValidateRegimeTime_Response`, response => {
                const regimes = response;
                resolve(regimes);
            });
        });
        return await regimePromise;
    }
    async fetchParkingStreets(Settings) {
        const parkingPromise = new Promise((resolve) => {
            CCCClient.SendMessage("PlanRoute_GetParkingStreets_Request", 1, Settings, 0, {
                Type: "ControlCenter",
                IndexNumber: 1,
                CustomerNumber: CCCClient.NodeCustomerNumber,
                ProjectNumber: CCCClient.NodeProjectNumber //CCCClient.NodeProjectNumber
            });
            Events.once(`PlanRoute_GetParkingStreets_Response`, response => {
                const parking = response;
                resolve(parking);
            });
        });
        return await parkingPromise;
    }
    async fetchDrivenRoutes(from, to, DeviceName) {
        const data = {
            FromDate: from,
            ToDate: to,
            DeviceName: DeviceName
        };
        const routesDrivenPromise = new Promise((resolve) => {
            CCCClient.SendMessage("PlanRoute_GetDrivenRoutes_Request", 1, data, 0, {
                Type: "ControlCenter",
                IndexNumber: 1,
                CustomerNumber: CCCClient.NodeCustomerNumber,
                ProjectNumber: CCCClient.NodeProjectNumber //CCCClient.NodeProjectNumber
            });
            Events.once(`PlanRoute_GetDrivenRoutes_Response`, response => {
                const routesDriven = response;
                resolve(routesDriven);
            });
        });
        return await routesDrivenPromise;
    }
    async existingRouteRequest(request) {
        const data = {
            Request: request
        };
        const existingPromise = new Promise((resolve) => {
            CCCClient.SendMessage("PlanRoute_CompareRouteRequest_Request", 1, data, 0, {
                Type: "ControlCenter",
                IndexNumber: 1,
                CustomerNumber: CCCClient.NodeCustomerNumber,
                ProjectNumber: CCCClient.NodeProjectNumber //CCCClient.NodeProjectNumber
            });
            Events.once(`PlanRoute_CompareRouteRequest_Response`, response => {
                const request = response;
                resolve(request);
            });
        });
        return await existingPromise;
    }
}
